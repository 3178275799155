import React from "react";
import Layout from "../components/layout"
// import stripe from "@stripe/react-stripe-js";

const ThankYou = () => {
  // TODO: we will get `CHECKOUT_SESSION_ID` through query params
  // use stripe to query more information checkout
  // display the information
  return (
    <Layout>
      <div style={{ marginLeft:"30px", marginRight: "30px" }} >
        <div>
          <h2>Order Received</h2>
          <hr />
        </div>
        <div>
          <p>Thank you. Your order has been received</p>
          <p>You should receive an email with details shortly. if you don't receive an email please contact us with your order information</p>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou;